<template>
  <div>
    <div v-if="load" class="bg" ref="pdf">
      <div class="pdf-content" ref="pdf2" id="pdf2">
        <!-- <div class="masker noprint"></div> -->
        
        <!-- <div  class="pages1" style="height:300px"></div>
        <div  class="pages2" style="height:300px;background-color:#ffffff;"></div>
        <div  class="pages3" style="height:300px"></div> -->
        <Cover :data="pdfInfo" class="pages1"></Cover>
        <Declaration :data="pdfInfo" class="pages2"></Declaration>
        <Summary :data="pdfInfo" class="pages3"></Summary>
        <Catlog :data="treeDataWithPageWithAddition" class="pages4"></Catlog>
        <PdfDetailResult></PdfDetailResult>
        <KeyFinanceRatio :pageStartFrom="lastDetailPage" :order-start-from="lastDetailLevel0Order" :offset="1"
          :class="'pages' + (treeData.length + 5)">
        </KeyFinanceRatio>
        <UpDownCustomerPdf :pageStartFrom="lastDetailPage" :order-start-from="lastDetailLevel0Order" :offset="2"
          :class="'pages' + (treeData.length + 6)">
        </UpDownCustomerPdf>
        <PurchaseAndSalePdf :pageStartFrom="lastDetailPage" :order-start-from="lastDetailLevel0Order" :offset="3"
          :class="'pages' + (treeData.length + 7)">
        </PurchaseAndSalePdf>
        <FinanceAndDubanPdf :pageStartFrom="lastDetailPage" :order-start-from="lastDetailLevel0Order" :offset="4"
          :class="'pages' + (treeData.length + 8)">
        </FinanceAndDubanPdf>
        <HistoryTaxConditionPdf :pageStartFrom="lastDetailPage" :order-start-from="lastDetailLevel0Order" :offset="5" :class="'pages' + (treeData.length + 9)">
        </HistoryTaxConditionPdf>
        <BackCover :data="pdfInfo" :class="'pages' + (treeData.length + 10)"></BackCover>
      </div>

      <!-- <button class="btn btn-primary btn-dl noprint" @click="handleBtnPdfDownload">下载PDF</button> -->
      <button class="btn btn-primary btn-dl noprint" @click="toPrint" v-if="!$route.query.isPreview">下载PDF</button>
      <!--      <div>-->
      <!--        <img :src="screenShoot" v-for="(screenShoot,index) in screenShoots" :key="index"-->
      <!--             style="width: 1240px;"/>-->
      <!--      </div>-->
    </div>
    <big-power-loading v-else height="100vh"></big-power-loading>
    <loading :active.sync="pdfLoading" backgroundColor="#fff" :can-cancel="true" :is-full-page="true" loader="Dots"
      color="#2f54eb"></loading>
    <!-- <div class="pdf-header"
      style="font-weight: bold; padding:15px 8px; width: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.85); color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
      页头
    </div>
    <div class="pdf-footer"
      style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
      <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
        我是页尾
      </div>
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
        第<div class="pdf-footer-page"></div>页 / 第<div class="pdf-footer-page-count"></div>页
      </div>
    </div> -->
  </div>


</template>

<script>
import { outputPDF } from '../../utils/outputPDF'
import pdfManage from '../../utils/pdf-manage'
import jsPDF from 'jspdf';

import html2canvas from 'html2canvas';
import Cover from "@/pages/pdfFrontGenerator/components/Cover";
import { findAiTaxLogo } from "@/api/pdf";
import Declaration from "@/pages/pdfFrontGenerator/components/Declaration";
import { mapState } from "vuex";
import { getCompanyInfo, getReportPara } from "@/api/threeYears";
import Summary from "@/pages/pdfFrontGenerator/components/Summary";
import Catlog from "@/pages/pdfFrontGenerator/components/Catlog";
import domtoimage from "dom-to-image"
import PdfDetailResult from "@/pages/pdfFrontGenerator/PdfDetailResult";
import { cloneDeep } from "lodash";
import KeyFinanceRatio from "@/pages/pdfFrontGenerator/components/KeyFinanceRatioPdf";
import PdfPage from "@/pages/pdfFrontGenerator/components/PdfPage";
import { PDFDocument } from "pdf-lib"
import download from "downloadjs"
import UpDownCustomerPdf from "@/pages/pdfFrontGenerator/components/UpDownCustomerPdf";
import { getInvoiceSection } from "@/api/api";
import moment from "moment";
import PurchaseAndSalePdf from "@/pages/pdfFrontGenerator/components/PurchaseAndSalePdf";
import FinanceAndDubanPdf from "@/pages/pdfFrontGenerator/components/FinanceAndDubanPdf";
import HistoryTaxConditionPdf from "@/pages/pdfFrontGenerator/components/HistoryTaxConditionPdf";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { dateFormat } from "@/filters/date";
import BackCover from "@/pages/pdfFrontGenerator/components/BackCover";
import { message } from 'element-ui';
import { async } from 'q';
import { resolve } from 'path';

export default {
  name: "PdfResult",
  components: {
    Cover,
    Declaration,
    Summary,
    Catlog,
    PdfDetailResult,
    KeyFinanceRatio,
    UpDownCustomerPdf,
    PurchaseAndSalePdf,
    FinanceAndDubanPdf,
    HistoryTaxConditionPdf,
    Loading,
    BackCover
  },
  metaInfo() {
    return {
      title: "PDF预览"
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      localStorage.setItem('authorization', this.$route.query.token)
    }

    this.load = false
    await this.detect();
    await this.loadCompanyInfo()
    await this.loadFindAiTaxLogo()
    await this.loadData();


    this.load = true
    this.treeDataWithPageWithAddition = this.treeDataWithPage;
    this.$eventBus.$on('appendToc', ({
      orderNumber, page,
      treeDataItem
    }) => {
      this.appendToc(orderNumber, page, treeDataItem)
    })


    const res = await getInvoiceSection(this.$route.params.uuid)
    const time = [moment(res.data.startDate).format("YYYY年MM月DD日"),
    moment(res.data.endDate).format("YYYY年MM月DD日")]
    this.$store.commit('PdfResult/setTimeRange', time);

  },
  computed: {
    ...mapState("ResultEditor", [
      "tag",
      "treeData",
      "overall",
      "maskStatus",
      "treeData",
      "discountPolicyTree",
      "downloadUrl"
    ]),
    /**
     * 给p2添加页面
     * @returns {*}
     */
    treeDataWithPage() {
      let currentPage = 1
      const data = cloneDeep(this.treeData)

      function walk(node) {

        node.page = currentPage
        if (node.children && node.children.length > 0) {
          for (const nodeChild of node.children) {

            walk(nodeChild)
          }
        }
        if (node.floor == 2 && (node.isDisplay)) {
          currentPage++;
        }
      }

      for (const nodeChild of data) {
        walk(nodeChild);
      }
      return data

    },

  },
  data() {
    return {
      pdfLoading: false,
      companyInfo: {},
      screenShoots: [],
      treeDataWithPageWithAddition: [],
      basicInfo: {},
      pdfInfo: {},
      load: false,
      reportType: "三年版",
      lastDetailLevel0Order: 0,
      lastDetailPage: 0,
    }
  },
  methods: {

    async loadCompanyInfo() {
      const res = await getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization
      })
      this.$store.commit("ResultEditor/setCompanyInfo", { companyInfo: res.data })
    },
    /**
     * 计算报告详情的最后的大项序号和最后一页
     */
    calculateDetailLast() {
      function findLastItemInLast(node) {
        let last = null;

        function walk(node) {
          if (node.children && node.children.length > 0) {
            for (const nodeChild of node.children) {
              walk(nodeChild)
            }
          } else {
            last = node;
          }
        }

        walk(node);
        return last;
      }

      const treeData = cloneDeep(this.treeDataWithPage)
      const last = [...treeData].pop();
      const lastInLast = findLastItemInLast(last);
      this.lastDetailLevel0Order = parseInt(last.orderNumber)
      this.lastDetailPage = parseInt(lastInLast.page)
    },
    /**
     *追加目录
     * @param {Number} order
     * @param {Number} pageNumber
     * @param {{
     *         titleName: '上下游客户分析',
     *         children: [
     *           {
     *             titleName: '上下游客户网络',
     *
     *           },
     *           {
     *             titleName: '上下游风险企业',
     *             children: [
     *               {
     *                 titleName: '上游风险企业'
     *               }, {
     *                 titleName: '下游风险企业'
     *               }, {
     *                 titleName: '风险类型说明'
     *               }
     *             ]
     *           },
     *           {
     *             titleName: '上下游客户分布',
     *             children: [
     *               {
     *                 titleName: '上游客户地区分布图'
     *               }, {
     *                 titleName: '下游客户地区分布图'
     *               }
     *             ]
     *           },
     *           {
     *             titleName: '购销分析',
     *             children: [
     *               {
     *                 titleName: '购销金额波动'
     *               }, {
     *                 titleName: '购销品类对比'
     *               }, {
     *                 titleName: '采购品类',
     *                 children: [
     *                   {
     *                     titleName: '2021年采购分析'
     *                   }, {
     *                     titleName: '2020年采购分析'
     *                   }, {
     *                     titleName: '2019年采购分析'
     *                   }
     *                 ]
     *               }, {
     *                 titleName: '销售品类',
     *                 children: [
     *                   {
     *                     titleName: '2021年销售分析'
     *                   }, {
     *                     titleName: '2020年销售分析'
     *                   }, {
     *                     titleName: '2019年采购分析'
     *                   }
     *                 ]
     *               }
     *             ]
     *           }
     *         ]
     *       }} treeDataItem
     */
    appendToc(order, pageNumber, treeDataItem) {

      const treeData = cloneDeep(this.treeDataWithPageWithAddition)

      let currentPage = pageNumber
      let currentOder = order

      function walk(node, orderLabel = []) {
        let order = 0;
        node.orderNumber = orderLabel.join('.')

        node.isDisplay = 1
        node.floor = orderLabel.length;
        node.page = currentPage
        if (node.children && node.children.length > 0) {
          for (const nodeChild of node.children) {
            order++;
            const currentOrderLabel = orderLabel.concat(order);
            walk(nodeChild, currentOrderLabel)
          }
        }
      }

      walk(treeDataItem, [currentOder]);

      this.treeDataWithPageWithAddition = [...treeData, treeDataItem].sort((v1, v2) => v1.orderNumber - v2.orderNumber)

    },
    async handleBtnPdfDownload() {
      const currentTimeStr = dateFormat.formatDate(new Date(this.$store.state.ResultEditor.basicInfo.exeTime), 'yyyy年MM月d日 hh:mm:ss');
      this.pdfLoading = true;
      const pdfPages = document.getElementsByClassName('pdf-page');

      // Create a new PDFDocument
      const pdfDoc = await PDFDocument.create()
      // Add a blank page to the document


      for (const pdfPage of pdfPages) {
        const base64 = await domtoimage.toPng(pdfPage, { quality: 1 })
        this.screenShoots.push(base64)
        const pdfImage = await pdfDoc.embedPng(base64)
        const imgDims = pdfImage.scale(0.8)
        const page = pdfDoc.addPage()
        page.setHeight(imgDims.height)
        page.setWidth(imgDims.width)
        page.drawImage(pdfImage, {
          x: page.getWidth() - imgDims.width,
          y: page.getHeight() - imgDims.height,
          width: imgDims.width,
          height: imgDims.height,
        })


      }
      const pdfBytes = await pdfDoc.save()

      download(pdfBytes, `税务预警风险检测报告-${this.$store.state.ResultEditor.basicInfo.companyName}-${currentTimeStr}.pdf`, "application/pdf");
      this.pdfLoading = false;
    },
    // 将元素转化为canvas元素
    // 通过 放大 提高清晰度
    // width为内容宽度
    async toCanvas(element, width) {
      // let canvasNone = document.getElementsByClassName('canvasNone')[0]

      // canvas元素
      const canvas = await html2canvas(element, {

        allowTaint: true, // 允许渲染跨域图片
        scale: .6,  // 增加清晰度
        // scale: window.devicePixelRatio * 2,  // 增加清晰度
        useCORS: true,// 允许跨域
        width: element.offsetWidth,
        // width: window.screen.availWidth*.6,
        height: element.offsetHeight,
        // height: window.screen.availHeight,

        windowWidth: document.body.fullWidth,
        windowHeight: document.body.fullHeight,
        x: 0,
        y: document.body.fullHeight,
        onrendered: function (canvas) {
          // document.body.appendChild(canvas);
        },
      });
      // 获取canavs转化后的宽度
      const canvasWidth = canvas.width;
      // 获取canvas转化后的高度
      const canvasHeight = canvas.height;
      // 高度转化为PDF的高度
      const height = (width / canvasWidth) * canvasHeight;
      // 转化成图片Data
      const canvasData = canvas.toDataURL('image/jpeg', 1.0);
      canvas.style.display = 'none'
      canvas.style.width = element.offsetWidth
      canvas.style.height = height
      canvas.classList.add('canvasNone')
      document.body.appendChild(canvas);

      return { width, height, data: canvasData };
    },


    async toPrint() {
      this.pdfLoading = true;
      this.$message({
        message: "温馨提示：正在努力生成pdf中，预计一分钟内，请勿离开当前页面。",
        type: "success"
      })
      setTimeout(() => {
        this.onPrint()
      }, 3000);
    },

    // async onPrint() {
    //   const A4_WIDTH = 592.28;
    //   const A4_HEIGHT = 841.89;
    //   // jsPDFs实例
    //   const pdf = new jsPDF({
    //     unit: 'pt',
    //     format: 'a4',
    //     orientation: 'p',
    //   });
    //   const ele = document.getElementById('pdf2')
    //   // 一页的高度， 转换宽度为一页元素的宽度
    //   const { width, height, data } = await this.toCanvas(ele, A4_WIDTH);
    //   console.log(width, height, data);
    //   let canvasNone = document.getElementsByClassName('canvasNone')[0]
    //   var cxt = canvasNone.getContext('2d');
    //   // 添加
    //   function addImage(_x, _y, pdf, data, width, height) {
    //     pdf.addImage(data, 'JPEG', _x, _y, width, height);
    //   }
    //   // 增加空白遮挡
    //   function addBlank(x, y, width, height, pdf) {
    //     pdf.setFillColor(255, 255, 255);
    //     pdf.rect(x, y, width, height, 'F');
    //   };
    //   //canvas的宽度除以pdf宽度的比例
    //   let scale = 0.6
    //   var i = 0

    //   var cutedHeight = 0
    //   var canvasNoneWidth = document.getElementsByClassName('canvasNone')[0].width
    //   console.log(width/canvasNoneWidth);
    //   //循环遍历pdf的高度，进行切割为单页
    //   while (cutedHeight <= height) {
    //     // //定义线条宽度
    //     // cxt.lineWidth = 1;
    //     // //电源线条颜色
    //     // cxt.strokeStyle = 'red';
    //     // //边角类型lineJoin='边叫类型'  边角类型:bevel:斜角,round:圆角,miter:尖角
    //     // cxt.lineJoin = 'round';
    //     // //开启绘画路径(声明开始划线)
    //     // cxt.beginPath();
    //     // //绘画开始位置move(X,Y)
    //     // cxt.moveTo(0, (A4_HEIGHT + cutedHeight )/scale- 1);
    //     // // 绘画目标位置lineTo(x, y)
    //     // cxt.lineTo(canvasNoneWidth , (A4_HEIGHT + cutedHeight )/scale- 1);
    //     // //stroke()填充路径就是连接开始和结束点
    //     // cxt.stroke();
    //     //进行截图判断 
    //     if (cutedHeight == 0) {
    //       let imageData = cxt.getImageData(0, A4_HEIGHT - 1, canvasNoneWidth, 1);
    //       addImage(0, 0, pdf, data, width, height);
    //       //判断当前行是否为全白色
    //       const iswhite = imageData.data.every((item) => {
    //         return item == 255
    //       })
    //       console.log(imageData, iswhite);
    //       // 增加分页
    //       pdf.addPage();
    //       i++
    //       cutedHeight = A4_HEIGHT
    //     } else {
    //       let imageData = cxt.getImageData(0, A4_HEIGHT + cutedHeight-1, width, 1);
    //       //判断当前行是否为全白色
    //       const iswhite = imageData.data.every((item) => {
    //         return item == 255
    //       })
    //       console.log(i + 1, imageData, iswhite);
    //       if (iswhite) {
    //         console.log(`当前${(i * 1) + (1 * 1)}当前全白`);
    //         addImage(0, 0 - cutedHeight, pdf, data, width, height);
    //         i++
    //         cutedHeight = A4_HEIGHT + cutedHeight

    //       } else {
    //         const pagesize = 1//每页查询的高度
    //         const loopTime = 30
    //         for (let index = 1; index <= loopTime; index++) {
    //           let imageData = cxt.getImageData(0, A4_HEIGHT + cutedHeight - pagesize * index, width, pagesize);
    //           const isfullWhite = imageData.data.every((item) => {        
    //             return item == 255
    //           })
    //           console.log(isfullWhite);
    //           if (isfullWhite) {
    //             console.log(`当前${(i * 1) + (1 * 1)}跳出循环`);
    //             addImage(0, 0 - cutedHeight, pdf, data, width, height);
    //             addBlank(0, 0 - cutedHeight + pagesize * index, canvasNoneWidth, pagesize * index, pdf)
    //             i++
    //             cutedHeight = A4_HEIGHT - pagesize * index + cutedHeight
    //             break
    //           } else if (!isfullWhite && index == loopTime) {
    //             console.log(`当前${(i * 1) + (1 * 1)}没有空白`);
    //             addImage(0, 0 - cutedHeight, pdf, data, width, height);
    //             i++
    //             cutedHeight = A4_HEIGHT + cutedHeight
    //             console.log(cutedHeight);
    //             break
    //           } else if (!isfullWhite && index != loopTime) {
    //             console.log(`当前${(i * 1) + (1 * 1)}行，向上第${index}行没有空白`);

    //           }

    //         }
    //       }


    //       if (cutedHeight <= height) {
    //         // 增加分页
    //         pdf.addPage();
    //       }

    //     }
    //   }
    //   pdf.save('test.pdf')


    // },
    async onPrint() {
      const currentTimeStr = dateFormat.formatDate(new Date(this.$store.state.ResultEditor.basicInfo.exeTime), 'yyyy年MM月d日 hh:mm:ss');
      // const pdfma = pdfManage(3, {
      const pdfma = pdfManage(this.treeData.length + 10, {
        type: 'a4',
        bgColors: ['#ffffff'], //将白色作为可截断的背景色
        padding: [10, 10, 10, 10]
      });

      const res = await pdfma.save(`税务预警风险检测报告-${this.$store.state.ResultEditor.basicInfo.companyName}-${currentTimeStr}.pdf`)
      if (res.code == 200) {
        this.closeloading()
      }

















      // window.pageYOffset = 0;
      // document.documentElement.scrollTop = 0
      // document.body.scrollTop = 0
      // const ele = document.getElementById('pdf2')
      // const header = document.querySelector('.pdf-header');
      // const footer = document.querySelector('.pdf-footer');

      // try {
      //   await outputPDF({
      //     element: ele,
      //     footer: footer,
      //     header: header,
      //     contentWidth: 592.28
      //   })

      // } catch (error) {
      //   message.error(typeof error === 'string' ? error : JSON.stringify(error))
      // }



      //print.js
      // this.$print(this.$refs.pdf2, {
      //   noPrint: '.noprint',
      //   onStart: () => {
      //     console.log('打印开始');
      //   },
      //   onEnd: () => {
      //     console.log('打印完成');
      //   }
      // });

      //原生js
      // document.getElementById('pdf2').focus();
      // document.getElementById('pdf2').style.zoom=0.64;
      // console.log(document.getElementById('pdf2').style.zoom);
      // window.print();
      // // window.location.reload();
      // document.getElementById('pdf2').style.zoom=1;

    },
    closeloading() {
      this.pdfLoading = false

    },
    /**
     * 载入基本信息
     * @returns {Promise<void>}
     */
    async detect() {
      const res = await getReportPara({ programId: this.$route.params.uuid })
      this.$store.commit("ResultEditor/setBasicInfo", {
        basicInfo: res.data
      });
      //ResultEditor

      if (res.code == 200) {
        if (res.data.reportType === '可视化+详情') {

        } else if (res.data.reportType === '三年版') {

        } else if (res.data.reportType === '详情') {

        }
      }
    },
    //初始化加载数据
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.params.nsrsbh,
        uuid: this.$route.params.uuid,
        action: '',
      });
      this.$store.commit("ResultEditor/setTreeData", { data: this.treeDataWithPage })
      this.calculateDetailLast();

    },
    /**
     * 获取pdf的logo,公章等信息
     * @returns {Promise<void>}
     */
    async loadFindAiTaxLogo() {
      const res = await findAiTaxLogo({
        programId: this.$route.params.uuid
      });
      this.pdfInfo = res.data
    }
  }
}
</script>

<style lang="stylus" scoped media="print">
.canvasNone{
  margin:0 auto;
}
.canvasNone{
  display:none
}
@media print {
 
  @page{

    margin:0
    
    }
  //不显示按钮
  .noprint {
    display: none;
  }
}

@import "~@/pages/pdfFrontGenerator/pdf.styl"
.pdf-content {
  width 1240px;
  margin 0 auto
}

.bg {
  overflow hidden
  //background #4E5969
  background #fff
  position relative;

  .masker {
    position absolute;
    z-index 1
    top 0;
    bottom 0;
    left 0;
    right 0;
  }
}

.btn-dl {
  position fixed;
  right 10px;
  top 10px;
  z-index 2
}
</style>
