<template>
  <div class="pdf-page-wrapper pdf-mode pdf-page" v-if="loaded">
    <div class="header">
      <div class="title">企业财税风险概述</div>
      <div class="border"></div>
    </div>
    <div class="content-section">
      <div class="title3">
        企业基本情况
      </div>
      <table class="table">
        <tr>
          <td class="solid" style="width: 222px">企业名称</td>
          <td>{{ data.companyName }}</td>
        </tr>
        <tr>
          <td class="solid">纳税人识别号</td>
          <td>{{ data.taxNo }}</td>
        </tr>
        <tr>
          <td class="solid">所属行业</td>
          <td>{{ data.hymc }}</td>
        </tr>
        <tr>
          <td class="solid">纳税人资质</td>
          <td>{{ data.taxpayerQualificationType }}</td>
        </tr>
        <tr>
          <td class="solid">进出口信用</td>
          <td>{{ data.managementCategory }}</td>
        </tr>
        <tr>
          <td class="solid">注册资本</td>
          <td>{{ data.regCapital }}</td>
        </tr>
        <tr>
          <td class="solid">实收资本</td>
          <td>{{ data.actualCapital }}</td>
        </tr>
        <tr>
          <td class="solid">参保人数</td>
          <td>{{ data.socialStaffNum }}</td>
        </tr>
        <tr>
          <td class="solid">成立日期</td>
          <td>{{ data.estiblishTime }}</td>
        </tr>
        <tr>
          <td class="solid">核准日期</td>
          <td>{{ data.approvedTime }}</td>
        </tr>

      </table>
    </div>
    <div class="content-section">
      <div class="title3">
        财税风险概述
      </div>
      <div class="as-follow-text">
        <p>本次财税分析检查的业务时间区间为：{{ dataFinancialRisk.period }}。</p>
        <p>根据您的企业涉税信息扫描结果显示：本次总检测项{{ dataFinancialRisk.total }}，其中高风险{{ dataFinancialRisk.highRisk }}项，中风险{{ dataFinancialRisk.middleRisk }}项，低风险{{ dataFinancialRisk.lowRisk }}项。建议您进一步进行账务自查，避免涉税损失持续扩大。</p>
        <p>具体如下：</p>
      </div>
      <BigPowerTable :data="dataTable" v-if="dataTable" class="table"></BigPowerTable>
    </div>

    <div class="footer"></div>
  </div>
</template>

<script>
import {getCompanyInfo, getFinancialRisk} from "@/api/threeYears";

export default {
  name: "Summary",
  data() {
    return {
      data: {},
      loaded: false,
      dataFinancialRisk:{}
    }
  },
  async mounted() {
    this.loaded = false
    await this.loadCompanyInfo()
    await this.loadFinancialRisk();
    this.loaded = true
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({programId: this.$route.params.uuid,desensitizationStatus:this.$route.query.desensitization})
      this.data = res.data
    },
    async loadFinancialRisk() {
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid
      })
      this.dataFinancialRisk = res.data.data;
    }
  },
  computed: {
    dataTable() {
      if (this.dataFinancialRisk.dataTable) {
        this.dataFinancialRisk.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === '高') {
              row[2].color = "#F53F3F";
            } else if (row[2].content === '中') {
              row[2].color = "#FF7D00";
            } else if (row[2].content === '低') {
              row[2].color = "#00B42A";
            }
          }

        })
      }
      return this.dataFinancialRisk.dataTable;

    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.content-section {
  width 1107px;
  margin 0 auto

  .table {
    width 100%

    td, th {
      font-size: 19px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: bold;
      color: #1D2129;
      line-height: 29px;
    }
  }
}

.as-follow-text {
  font-size: 22px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #1D2129;
  margin 22px auto;
  line-height: 28px;
  p{
    margin-bottom 28px
  }
}
.pdf-page-wrapper{
  background #fff
  padding 33px 0
}
</style>
