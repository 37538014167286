<template>
  <PdfPage :node="node">
    <KeyFinanceRatio mode="pdf" :node="node"></KeyFinanceRatio>
  </PdfPage>
</template>

<script>
import PdfPage from "@/pages/pdfFrontGenerator/components/PdfPage";
import KeyFinanceRatio from "@/pages/Result/ThreeYearResult/financialAnalysis/KeyFinanceRatio";

export default {
  name: "KeyFinanceRatioPdf",
  props: {
    pageStartFrom: Number,
    orderStartFrom: Number,
    offset: Number
  },
  components: {
    PdfPage,
    KeyFinanceRatio
  },
  data() {
    return {
      loaded: false
    }
  },
  async mounted() {
    this.loaded = false
    this.loaded = true
    this.$eventBus.$emit('appendToc', this.node)
  },
  methods: {},
  computed: {
    node() {
      return {
        page: this.pageStartFrom + this.offset,
        orderNumber: this.orderStartFrom + this.offset,
        treeDataItem: {
          titleName: '关键财税比率',
          children: [
            {
              titleName: '关键税负率',

            },
            {
              titleName: '关键财务比率',
            }
          ]
        }
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.pdf-page-wrapper {
  padding 33px 0
}
</style>
