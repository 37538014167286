<template>
  <div class="pdf-page-wrapper ">
    <div class="pdf-page">
      <img :src="data.coverImage" class="coverImage" crossorigin="anonymous"/>
      <div class="cover " :class="coverClass">
        <div class="logo-wrapper">
          <img :src="data.backgroundColorImage" class="logo" v-if="data.backgroundColorImage"
               crossorigin="anonymous">
          <div class="companyName" v-if="data.coverDisplayCompanyName">
            {{ $store.state.ResultEditor.companyInfo.companyName }}
          </div>
        </div>

        <div class="bottom">
          <div class="time" v-if="data.coverAssertor">{{ data.coverAssertor }}</div>
          <div class="time">{{ $store.state.ResultEditor.basicInfo.exeTime | dateFormat }}</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "Cover",
  props: {
    data: Object
  },
  data() {
    return {
      backGroundImage: ''
    }
  },
  methods: {},
  computed: {
    coverClass(){
      return this.data.coverStructurePosition == 2 ? 'center-wrapper' : 'left-wrapper'
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.coverImage {
  width 100%;
  height: 100%;
  display: block;
  z-index 2;
}

.cover {
  width 100%
  position absolute;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.logo {
  width 329px
}

.left-wrapper {


  .logo-wrapper {
    top 67px;
    left 67px
    position absolute
    display flex
    flex-direction column


    .companyName {
      font-size 36px
      color #060b55;
      font-weight bolder
      margin-top 30px
    }


    .title {
      font-size 64px
      color #060b55;
      font-weight bolder
      top 187px;
      left 67px
      position absolute
    }



  }
  .bottom {
    font-size: 33px;
    font-weight: bold
    color: #030852;
    line-height: 50px;
    position absolute
    bottom 67px;
    left 67px
    z-index 1
  }
}

.center-wrapper {
  display flex
  flex-direction column
  justify-content center

  .logo {
    position absolute
    top 163px
    z-index 2
    left 50%;
    transform translateX(-50%)
  }

  .companyName {
    position absolute
    top 800px
    left 50%;
    transform translateX(-50%)
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #030852;
    line-height: 36px;
  }

  .bottom{
    position absolute
    left 50%;
    bottom 120px;
    transform translateX(-50%)
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #030852;
    line-height: 36px;
    .time{
      margin-top 24px
    }
  }
}

</style>
